







































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsFooterModal from '@/modules/emailEditor/components/VsFooterModal/Index.vue'
import VsFooterCard from '@/modules/emailEditor/components/VsFooterCard/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import {
    deleteFooterById,
    getFooters,
    updateFooter,
} from '@/api/consoleApi/contents/footers'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { IFooterData } from '@/api/consoleApi/dto/footers.dto'

@Component({
    name: 'FootersIndex',
    components: {
        VsSectionHeader,
        VsLoader,
        VsContainer,
        VsFooterModal,
        VsConfirm,
        VsFooterCard,
    },
})
export default class extends Vue {
    private loading = false
    private firstLoader = false
    private total = 0
    private emptyState = false
    private defaultValue = false
    private pagination = {
        page: 1,
        itemsPerPage: 10,
    }

    private filters: {
        search: string
        entityLabels: any[]
    } = {
        search: '',
        entityLabels: [],
    }

    $refs!: any

    private footers = []

    async beforeMount () {
        this.firstLoader = true
        this.getFooters()
        this.firstLoader = false
    }

    @Watch('filters', { immediate: false, deep: true })
    private setCurrentPageAndGetFooters () {
        if (this.pagination.page !== 1) {
            this.pagination.page = 1
        } else {
            this.getFooters()
        }
    }

    private buildParams () {
        const params: any = {
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
        }

        return params
    }

    openCreateFooterModal () {
        this.$refs.vsFooterModal.openModal()
    }

    @Watch('pagination', { immediate: true, deep: true })
    private async getFooters () {
        try {
            const resp = await getFooters(
                this.buildParams(),
            )
            this.emptyState = resp.data.meta.pagination.total === 0
            this.total = resp.data.meta.pagination.total
            this.footers = resp.data.data
        } catch (e) {
        }
    }

    async deleteFooterById (footerId: number) {
        try {
            await this.$refs.deleteFooterConfirm.openConfirm()
        } catch (e) {
            return
        }

        this.loading = true
        try {
            await deleteFooterById(footerId)

            this.$root.$vsToast({
                heading: 'Footer eliminato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            await this.getFooters()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del footer',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async setDefault (event: boolean, footer: any) {
        this.defaultValue = event
        this.loading = true
        try {
            const newFooter: IFooterData = {
                name: footer.name,
                html: footer.html,
                default: this.defaultValue,
            }
            await updateFooter(footer.id, newFooter)

            this.$root.$vsToast({
                heading: 'Footer modificato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            await this.getFooters()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del footer predefinito',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }
}
