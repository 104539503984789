






















































import { Component, Vue } from 'vue-property-decorator'
import { IFooterData } from '@/api/consoleApi/dto/footers.dto'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsFooterNameModal from '../VsFooterNameModal/Index.vue'
import {
    getEmailContentTags,
    getEmailContentButtons,
} from '@/utils/personalizations'
import { createFooter, updateFooter } from '@/api/consoleApi/contents/footers'
import { get } from 'lodash'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsLoader from '@/components/VsLoader/Index.vue'

@Component({
    name: 'vsFooterModal',
    components: {
        VsContainer,
        VsFooterNameModal,
        VsFullTopBarLayout,
        VsLoader,
    },
})
export default class extends Vue {
    private footer: IFooterData = {
        name: 'Footer senza nome',
        html: '',
        default: false,
    }

    private open = false
    private loading = false
    private footerId = ''
    $refs: any

    get consoleUrl () {
        return `${window.location.protocol}//${window.location.hostname}`
    }

    beforeMount () {
        window.addEventListener('message', this.receiveMessage)
    }

    beforeDestroy () {
        window.removeEventListener('message', this.receiveMessage)
    }

    private receiveMessage (event: any) {
        try {
            const data = JSON.parse(event.data)
            this.actionsWithObjectMessage(data, event)
        } catch (e) {
            this.actionsWithStringMessage(event)
        }
    }

    private actionsWithObjectMessage (objectMessage: any, event: any) {
        if (!objectMessage.name) return
        switch (objectMessage.name) {
            case 'save':
                this.saveContent(objectMessage.value)
                break
            case 'openGallery':
                window.openVsMediaLibrary(objectMessage.mediaGalleryOptions).then((url: string) => {
                    this.$refs.ckeditorIframe.contentWindow.postMessage(
                        JSON.stringify({
                            name: objectMessage.type === 'link' ? 'linkSelected' : 'imageSelected',
                            url,
                        }),
                        '*',
                    )
                }).catch((e: any) => { console.log(e) })
                break
        }
    }

    private actionsWithStringMessage (event: any) {
        switch (event.data) {
            case 'ready':
                this.loading = false
                event.source.postMessage(JSON.stringify({
                    name: 'init',
                    data: {
                        value: this.footer.html,
                        tags: getEmailContentTags([], this.consoleUrl),
                        buttons: getEmailContentButtons(),
                    },
                }), event.origin)
                break
        }
    }

    private sendSaveMessage () {
        this.$refs.ckeditorIframe.contentWindow.postMessage('save', '*')
    }

    public openModal (footer: any) {
        this.loading = true
        this.clearFooter()

        if (footer) {
            this.footer = JSON.parse(JSON.stringify(footer))
            this.footerId = footer.id
        }
        this.open = true
    }

    private clearFooter () {
        this.footer = {
            name: 'Footer senza nome',
            html: '',
            default: false,
        }
        this.footerId = ''
    }

    private async saveContent (html: string) {
        this.footer.html = html
        if (this.footerId) {
            await this.updateFooter()
            return
        }
        await this.createFooter()
    }

    private async createFooter () {
        if (!this.footer.html) {
            this.$root.$vsToast({
                heading: 'Imposta un contenuto HTML',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            return
        }
        try {
            await createFooter(this.footer)
            this.closeModal()
            this.$emit('content-saved')
            this.$root.$vsToast({
                heading: 'Footer creato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la creazione del footer',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    private async updateFooter () {
        if (!this.footer.html) {
            this.$root.$vsToast({
                heading: 'Imposta un contenuto HTML',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            return
        }
        try {
            const data = {
                name: this.footer.name,
                html: this.footer.html,
                default: get(this.footer, 'default_footer', false),
            }
            await updateFooter(this.footerId, data)
            this.closeModal()
            this.$emit('content-saved')
            this.$root.$vsToast({
                heading: 'Footer aggiornato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del footer',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
    }

    public closeModal () {
        this.open = false
    }

    private async openFooterNameModal () {
        try {
            await this.saveFooterName()
        } catch (e) {}
        this.$refs.vsFooterNameModal.close()
    }

    private async saveFooterName (saveButton?: string) {
        this.footer.name = await this.$refs.vsFooterNameModal.open(this.footer.name, saveButton)
    }
}
