








































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsFooterCard',
    components: {
        VsImage,
        VsListingCard,
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private footer!: {
        id: number
        name: string
        html: string
        plain: string
        default_footer: boolean
        user_id: number
    }
}
